import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['menu', 'button']
  static values = { open: Boolean }

  connect() {
  }

  toggle() {
    this.openValue = !this.openValue
  }

  openValueChanged() {
    if (this.openValue) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.menuTarget.classList.add('inline-block')
    this.menuTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('inline-block')
  }

  hide() {
    this.menuTarget.classList.add('hidden')
    this.menuTarget.classList.remove('inline-block')
    this.buttonTarget.classList.add('inline-block')
    this.buttonTarget.classList.remove('hidden')
  }

}
