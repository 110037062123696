import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "nameField" ]
  static values = { editUrl: String, updateUrl: String, scopeUrl: String, stopVisit: Boolean }

  visit() {
    this.stopVisitValue = false
    setTimeout(() => { if (!this.stopVisitValue) { Turbo.visit(this.scopeUrlValue) } }, 250)
  }

  startEdit(event) {
    this.stopVisitValue = true // so first click doesn't trigger this.visit()
    event.stopPropagation() // so dblclick won't do other things like create a task
    fetch(this.editUrlValue)
      .then(response => response.text())
      .then(html => this.element.outerHTML = html)
  }

  stopPropagation(event) {
    // catch dblclick when selecting text so it doesn't bubble up to create an event
    event.stopPropagation()
  }

  finishEdit() {
    this.saveAndReplaceWithShow()
  }

  saveAndReplaceWithShow() {
    parent = this.element.parentElement // because we are swapping the contents of this very controller below
    fetch(`${this.updateUrlValue}.js`, {
      method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         'X-CSRF-Token': this.getMetaValue("csrf-token")
       },
       body: JSON.stringify({ scope: { name: this.nameFieldTarget.value }})
     })
    .then(response => response.text())
    .then(html => {
      this.element.outerHTML = html
      // we do this after the server is done
      parent.dispatchEvent(new CustomEvent("didEditScopeName", { bubbles: true}))
    })
  }

  finishWithKeyboard(event) {
    const returnKeyCode = 13
    const escapeKeyCode = 27
    if (event.keyCode == returnKeyCode || event.keyCode == escapeKeyCode) {
      this.finishEdit()
    }
  }
}
