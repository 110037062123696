import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "namingAffordance", "flaggingAffordance", "flaggingUpdater", "task" ]
  static values = { hasDoneTasksNotShown: Boolean }

  connect() {
    // do this without delay when the page loads
    this.applyVisibilityChange()
  }

  update() {
    // delay when updating after some action so the effect is perceived as a consequence
    setTimeout(() => { this.applyVisibilityChange() }, 300);
  }

  applyVisibilityChange() {
    if (this.taskTargets.length > 0 || this.hasDoneTasksNotShownValue || this.namingAffordanceTarget.dataset.nameExists == 'true') {
      this.showAffordances()
    } else {
      this.hideAffordances()
    }
  }

  showAffordances() {
    this.namingAffordanceTarget.classList.remove('hidden-important')
    if (this.hasFlaggingAffordanceTarget) {
      this.flaggingAffordanceTarget.classList.remove('hidden-important')
    }
  }

  hideAffordances() {
    this.namingAffordanceTarget.classList.add('hidden-important')
    if (this.hasFlaggingAffordanceTarget) {
      this.flaggingAffordanceTarget.classList.add('hidden-important')
      this.flaggingUpdaterTarget.dispatchEvent(new CustomEvent("didHideFlaggingAffordance", { bubbles: true }))
    }
  }
}
