import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "task" ]
  static values = { url: String }

  pursueNotationByKeyboard(event) {
    const nKeyCode = 78 
    if (event.keyCode == nKeyCode && this.taskTarget.dataset.selected == 'true' && this.taskTarget.dataset.editing != 'true') {
      Turbo.visit(this.urlValue)
    }
  }

}

