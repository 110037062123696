import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "task" ]
  static values = { url: String }

  pursueUnpackingByKeyboard(event) {
    const uKeyCode = 85 
    if (event.keyCode == uKeyCode && this.taskTarget.dataset.selected == 'true' && this.taskTarget.dataset.editing != 'true') {
      Turbo.visit(this.urlValue)
    }
  }

}


