import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "task" ]

  pursueDeletionByKeyboard(event) {
    const backspaceKeyCode = 8
    if (event.keyCode == backspaceKeyCode) {
      this.taskTargets.forEach((element) => {
       if (element.dataset.selected == 'true' && element.dataset.editing != 'true') {
        this.delete(element)
      }})
    }
  }

  startDeleteFromElsewhere(event) {
    const element = event.target
    this.delete(element)
  }

  delete(element) {
    // we'll fire on the parent afterward because the element itself gets removed
    const parent = element.parentElement
    element.remove()
    const url = element.dataset.deletionUrl
    fetch(url, {
      method: 'DELETE',
       headers: {
         'X-CSRF-Token': this.getMetaValue("csrf-token")
       }
     })
     parent.dispatchEvent(new CustomEvent("didDeleteTask", { bubbles: true }))
  }

}