import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "task" ]

  pursueDenialByKeyboard(event) {
    const backspaceKeyCode = 8
    if (event.keyCode == backspaceKeyCode) {
      this.taskTargets.forEach((element) => {
       if (element.dataset.selected == 'true' && element.dataset.editing != 'true') {
         element.classList.add("shake")
         setTimeout(() => { element.classList.remove("shake") }, 1000);
      }})
    }
  }

  denyFromElsewhere(element) {
    // replace with the original
    console.log("Deletion denied")
  }
}

