import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "nameField" ]
  static values = { editUrl: String, updateUrl: String, justCreated: Boolean }

  startEdit(event) {
    event.stopPropagation() // dblclick does other things elsewhere
    this.element.dispatchEvent(new CustomEvent("willEditTask", { bubbles: true }))
    fetch(this.editUrlValue)
      .then(response => response.text())
      .then(html => this.element.outerHTML = html)
  }

  stopPropagation(event) {
    // catch dblclick when selecting text so it doesn't bubble up to create an event
    event.stopPropagation()
  }

  finishEdit() {
    // enableDragging has to be fired before the "delete" event in order to work
    this.element.dispatchEvent(new CustomEvent("didEditTask", { bubbles: true }))
    if ( this.nameFieldTarget.value === "" ) {
      this.element.dispatchEvent(new CustomEvent("delete", { bubbles: true }))
    } else {
      this.saveAndReplaceWithShow()
    }
  }

  saveAndReplaceWithShow() {
    fetch(`${this.updateUrlValue}.js`, {
      method: 'PUT',
       headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         'X-CSRF-Token': this.getMetaValue("csrf-token")
       },
       body: JSON.stringify({ task: { name: this.nameFieldTarget.value }})
     })
    .then(response => response.text())
    .then(html => {
      if (this.justCreatedValue == true) {
        // do this before replacing the element, otherwise the event won't bubble
          this.element.dispatchEvent(new CustomEvent("didCreateTask", { bubbles: true }))
      }
      this.element.outerHTML = html
    })
  }

  finishWithKeyboard(event) {
    const returnKeyCode = 13
    const escapeKeyCode = 27
    if (event.keyCode == returnKeyCode || event.keyCode == escapeKeyCode) {
      this.finishEdit()
    }
  }
}
