import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "stepsContainer" ]

  connect() {
    const distanceToScroll = this.stepsContainerTarget.scrollWidth - this.stepsContainerTarget.offsetWidth
    if (distanceToScroll > 0) {
      this.stepsContainerTarget.scrollLeft += distanceToScroll;
    }
  }
}
