import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "form", "impulse", "old", "struggle", "new" ]

  save() {
    fetch(this.formTarget.action, {
      method: 'PATCH',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': this.getMetaValue("csrf-token")
      },
      body: JSON.stringify({ motivation: {
        impulse: this.impulseTarget.value,
        old: this.oldTarget.value,
        struggle: this.struggleTarget.value,
        new: this.newTarget.value }})
    })
  }

}
