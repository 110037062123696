import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "inContainer", "doneContainer" ]
  static values = { hasDoneTasksNotShown: Boolean, persistsAsDone: Boolean, scopeUrl: String }

  connect() {
    this.update()
  }

  update() {
    if (this.hasDoneTasks() && this.hasNothingOutstanding()) {
      if (!this.persistsAsDone) { this.persistDoneAs(true) }
      this.inContainerTarget.classList.add('container--checkmark-indicator')
    } else {
      if (this.persistsAsDone) { this.persistDoneAs(false) }
      this.inContainerTarget.classList.remove('container--checkmark-indicator')
    }
  }

  persistDoneAs(value) {
    // this is effectively a cache so we don't hit the server unnecessarily
    this.persistsAsDone = value

    fetch(`${this.scopeUrlValue}.js`, {
      method: 'PUT',
       headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         'X-CSRF-Token': this.getMetaValue("csrf-token")
       },
       body: JSON.stringify({ scope: { done: value }})
     }).then(response => response.text())
    .then(html => {
       this.element.dispatchEvent(new CustomEvent("didChangeScopeDoneness", { bubbles: true }))
     })
  }

  hasNothingOutstanding() {
    return (this.inContainerTarget.children.length == 0)
  }

  hasDoneTasks() {
    // on show, we refer to the done container
    // on an index, the done tasks aren't shown and we check the value
    if (this.hasDoneContainerTarget) {
      return (this.doneContainerTarget.children.length > 0)
    } else {
      return this.hasDoneTasksNotShownValue
    }
  }

}
