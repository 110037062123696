import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "partial" ]
  static values = { url: String }

  update() {
    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => this.partialTarget.outerHTML = html)
  }

}