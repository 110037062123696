import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "well" ]
  static values = { url: String }

  startCreate() {
    this.scrollToBottom()

    this.element.dispatchEvent(new CustomEvent("willCreateTask", { bubbles: true }))
    fetch(`${this.urlValue}.js`, {
      method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'X-CSRF-Token': this.getMetaValue("csrf-token")
       },
       body: JSON.stringify({ task: { status: this.element.dataset.containerStatus }})
     })
    .then(response => response.text())
    .then(html => {
      this.wellTarget.insertAdjacentHTML('beforeend', html)
      this.element.dispatchEvent(new CustomEvent("didInsertBlankTask", { bubbles: true }))
    })
  }

  scrollToBottom() {
    // this lets us use the bottom padding on the container to keep
    // the newly added task at a comfortable height
    this.element.scrollTop = this.element.scrollHeight
  }

}
