import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.adjust()
  }

  getScrollHeight(elm) {
    var savedValue = elm.value
    elm.value = ''
    elm._baseScrollHeight = elm.scrollHeight
    elm.value = savedValue
  }

  adjust() {
    var minRows = this.element.getAttribute('data-min-rows')|0, rows;
    !this.element._baseScrollHeight && this.getScrollHeight(this.element)

    this.element.rows = minRows
    rows = Math.ceil((this.element.scrollHeight - this.element._baseScrollHeight) / 16)
    this.element.rows = minRows + rows
  }
}
