import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [ "well", "scopeAffordanceBg", "toggle", "toggleUpdater" ]
  static values = { url: String}

  connect() {
    if (this.toggleIsOn()) {
      this.indicateFlagged()
    } else {
      this.indicateUnflagged()
    }
  }

  toggleIsOn() {
    if (this.toggleTarget.dataset.toggleEnabledValue == 'true') {
      return true
    } else {
      return false
    }
  }

  toggle(event) {
    event.stopPropagation()
    if (this.toggleIsOn()) {
      this.flag()
    } else {
      this.unflag()
    }
  }

  flag() {
    this.indicateFlagged()

    fetch(this.urlValue, {
      method: 'POST',
       headers: {
         'X-CSRF-Token': this.getMetaValue("csrf-token")
       }
     })
    .then(response => response.text())
  }

  indicateFlagged() {
    this.wellTarget.classList.add("container--flagged")
    this.scopeAffordanceBgTarget.classList.add("scope-affordance-bg--flagged")
  }

  indicateUnflagged() {
    this.wellTarget.classList.remove("container--flagged")
    this.scopeAffordanceBgTarget.classList.remove("scope-affordance-bg--flagged")
  }

  unflagRemotely() {
    // to distinguish from unflagging via the toggle
    this.unflag()
    this.toggleUpdaterTarget.dispatchEvent(new CustomEvent("didUnflagRemotely", { bubbles: true }))
  }

  unflag() {
    this.indicateUnflagged()

    fetch(this.urlValue, {
      method: 'DELETE',
       headers: {
         'X-CSRF-Token': this.getMetaValue("csrf-token")
       }
     })
    .then(response => response.text())
  }
 
}
