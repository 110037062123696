import ApplicationController from "../application_controller"
import { Sortable } from "sortablejs"

export default class extends ApplicationController {

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'sequence_group',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    fetch(event.item.dataset.scopeMovementUrl, {
      method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         'X-CSRF-Token': this.getMetaValue("csrf-token")
       },
       body: JSON.stringify({ movement: { status: event.to.dataset.status, position: event.newIndex + 1 }})
     })
  }
 }
