import ApplicationController from "../application_controller"
import { Sortable, MultiDrag } from "sortablejs"

Sortable.mount(new MultiDrag());

export default class extends ApplicationController {

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'container',
      animation: 150,
      multiDrag: true,
      multiDragKey: 'shift',
      selectedClass: 'selected-task',
      onEnd: this.end.bind(this),
      onSort: this.reportChange.bind(this),
      onSelect: this.didSelect.bind(this),
      onDeselect: this.didDeselect.bind(this)
    })
  }

  reportChange(event) {
    this.element.dispatchEvent(new CustomEvent("sortableChanged", { bubbles: true }))
  }

  end(event) {
    fetch(event.item.dataset.relocationsUrl, {
      method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': this.getMetaValue("csrf-token")
        },
        body: JSON.stringify({ location: { container_type: event.to.dataset.containerType, container_id: event.to.dataset.containerId, status: event.to.dataset.containerStatus, position: event.newIndex + 1 }})
    })
  }

  disable(event) {
    this.sortable.option("disabled", true)
  }

  enable(event) {
    this.sortable.option("disabled", false)
  }

  deselect(event) {
    Sortable.utils.deselect(event.target)
  }

  didSelect(event) {
    event.item.dataset.selected = true
  }

  didDeselect(event) {
    event.item.dataset.selected = false
  }

 }