import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "indicator", "handle" ]
  static values = { enabled: Boolean }

  connect() {
    this.setClasses()
  }

  toggle() {
    this.enabledValue = !this.enabledValue
    this.element.dispatchEvent(new CustomEvent("toggled", { bubbles: true }))
    this.setClasses()
  }

  toggleOff() {
    if (this.enabledValue == true) {
      this.toggle()
    } else {
      // it's already off
    }
  }

  setClasses() {
    if (this.enabledValue) {
      this.setEnabledClasses()
    } else {
      this.setDisabledClasses()
    }
  }

  setEnabledClasses() {
    this.indicatorTarget.classList.remove('toggle-indicator--disabled')
    this.indicatorTarget.classList.add('toggle-indicator--enabled')
    this.handleTarget.classList.remove('toggle-handle--disabled')
    this.handleTarget.classList.add('toggle-handle--enabled')
  }
  
  setDisabledClasses() {
    this.indicatorTarget.classList.remove('toggle-indicator--enabled')
    this.indicatorTarget.classList.add('toggle-indicator--disabled')
    this.handleTarget.classList.remove('toggle-handle--enabled')
    this.handleTarget.classList.add('toggle-handle--disabled')
  }
}
